@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;900&display=swap");

@layer components {
  .centeredOnScreen {
    @apply absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4;
  }
  .teamMemberContainer {
    @apply h-[280px] w-[220px] rounded-md border-4 border-blue-300/20 bg-blue-300/10 shadow-md hover:scale-105;
  }
  .navButton {
    @apply font-black mx-3 text-xl uppercase hover:scale-105;
  }
}

header,
body {
  font-family: "Nunito", sans-serif;
}

::-webkit-scrollbar {
  width: 0.8em;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 100vw;
}
